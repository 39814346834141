import { Component, OnInit, ViewChild } from '@angular/core';
import { PersonService } from '../service/person.service';
import { PersonCriteria } from '../service/person-criteria';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { Router } from '@angular/router';
import { faEnvelopeOpenText, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import {PersonEntity} from '../../domain/person/person-entity';
import {MessageDialogAction} from '../../common/message-dialog/message-dialog.component';
import {InvitationResult} from '../../domain/person/invitationResult';
import {ObjectResponse} from '../../domain/response/ObjectResponse';

@Component({
  selector: 'app-person-search',
  templateUrl: 'person-search.component.html',
  styleUrls: ['person-search.component.css'],
})
export class PersonSearchComponent implements OnInit {
  personCriteria = new PersonCriteria();
  response: PagedResponse<PersonEntity> = null;
  allSelected: boolean = false;
  selectedIds: Set<number> = new Set();
  faUserXmark = faUserXmark;
  faEnvelopeOpenText = faEnvelopeOpenText;
  @ViewChild('selectAll') selectAll;
  bulkAction: string = null;
  actions: MessageDialogAction[];

  @ViewChild('actionConfirmDialog') actionConfirmDialog;
  actionTitle: string = null;
  actionLabel: string = null;
  actionPrompt: string = null;
  actionDetails: Array<string|string[]> = null;

  constructor(private personService: PersonService, private router: Router) {
    this.doAction = this.doAction.bind(this);
    this.doCancel = this.doCancel.bind(this);
  }

  ngOnInit(): void {
    this.searchAction();
  }

  public searchAction() {
    return this.personService
      .findPersonsByCriteria(this.personCriteria)
      .subscribe((pagedResponse: PagedResponse<PersonEntity>) => {
        this.response = pagedResponse;
      });
  }

  public clearAction() {
    this.personCriteria.name = null;
    this.searchAction();
  }

  public newPersonAction() {
    this.router.navigate(['/person-edit', -1]);
  }

  onPageChanged(event: any): void {
    if (event.page && this.personCriteria) {
      this.personCriteria.pageNumber = event.page;
      this.searchAction();
    }
  }

  onPageSizeChanged(event): void {
    const old = this.personCriteria.pageNumber * this.personCriteria.pageSize;
    debugger;
    this.personCriteria.pageSize = Number(event.target.value);
    this.personCriteria.pageNumber = Math.floor(old / this.personCriteria.pageSize)+1;
    this.searchAction();
  }

  toggleSelection(e, i) {
    const person = this.response.searchResults[i];

    let inSelection: boolean;
    if (this.allSelected) {
      inSelection = !e.target.checked;
    } else {
      inSelection = e.target.checked;
    }

    if (inSelection) {
      this.selectedIds.add(person.personId);
    } else {
      this.selectedIds.delete(person.personId);
    }

    if (this.selectedIds.size === 0) {
      this.selectAll.nativeElement.indeterminate = false;
    } else {
      this.selectAll.nativeElement.indeterminate = true;
    }
  }

  invitePerson(person: PersonEntity) {
    this.actionTitle = 'Invite selected people?';
    this.actions = [
      {
        label: 'Invite',
        handler: () => this.doInvitePerson.call(this, person),
        classes: 'btn-primary'
      },
      {
        label: 'Cancel',
        handler: this.doCancel
      }
    ];
    const selectedPeople = this.allSelected
      ? this.response.total - this.selectedIds.size
      : this.selectedIds.size;
    this.actionPrompt = `Are you sure you want to invite ${person.firstName} ${person.familyName}?`;
    this.actionConfirmDialog.show();
  }

  async doInvitePerson(person: PersonEntity) {
    this.actionConfirmDialog.hide();
    let response: ObjectResponse<InvitationResult[]>;
    let error;
    try {
      response = await this.personService.invite([person.personId], false).toPromise();
    } catch (e) {
      e.message = `Error inviting ${person.firstName} ${person.familyName}: ${e.message}`;
      error = e;
    }

    const invitation = response?.object?.[0];

    if (invitation.success) {
      this.bulkAction = null;
      this.actionTitle = 'People invited';
      this.actionPrompt = `${person.firstName} ${person.familyName} has been invited (invitation expires ${
        (new Date(response.object[0].invitationExpiry)).toLocaleDateString()}).`
      this.actions = [
        {
          label: 'Ok',
          handler: () => this.actionConfirmDialog.hide()
        }
      ];
      this.actionConfirmDialog.show();
    } else {
      this.actionTitle = 'Error inviting people';
      this.actionPrompt = `There was an error inviting ${person.firstName} ${person.familyName}. Please try again.`
      this.actions = [
        {
          label: 'Ok',
          handler: () => this.actionConfirmDialog.hide(),
          classes: 'btn-primary'
        }
      ];
      this.actionConfirmDialog.show();
      throw error || new Error(`Error inviting ${person.firstName} ${person.familyName}: ${invitation?.errorMessage || 'Unknown error'}.`);
    }
  }

  confirmAction() {
    switch (this.bulkAction) {
      case 'Invite':
        this.actions = [
          {
            label: 'Invite',
            handler: this.doAction,
            classes: 'btn-primary'
          },
          {
            label: 'Cancel',
            handler: this.doCancel
          }
        ];
        this.actionTitle = 'Invite selected people?';
        this.actionDetails = null;
        const selectedPeople = this.allSelected
          ? this.response.total - this.selectedIds.size
          : this.selectedIds.size;
        this.actionPrompt = `Are you sure you want to invite ${selectedPeople} ${
          selectedPeople > 1 ? 'people' : 'person'}?`;
        this.actionConfirmDialog.show();
        break;
    }
  }

  async doAction() {
    switch (this.bulkAction) {
      case 'Invite': {
        // TODO
        this.actionConfirmDialog.hide();

        let response: ObjectResponse<InvitationResult[]>;
        let error: Error;
        try {
          response = await this.personService.invite([...this.selectedIds.values()], this.allSelected).toPromise();
        } catch (e) {
          error = e;
        }

        const errored = [];
        const success = [];
        const errors = [];

        if (response.status === 200 && response.object) {
          for (const invitation of response.object) {
            if (invitation.success) {
              success.push(`${invitation.givenName} ${invitation.familyName} (${invitation.email})`);
            } else {
              errored.push(`${invitation.givenName} ${invitation.familyName} (${invitation.email})`);
              errors.push(`Error inviting ${invitation.givenName} ${invitation.familyName}: ${invitation?.errorMessage || 'Unknown error'}.`);
            }
          }
        } else {
          error = new Error(`Unknown error inviting people (${response.status}).`);
        }

        this.actionPrompt = null;
        this.bulkAction = null;
        this.actions = [
          {
            label: 'Ok',
            handler: () => this.actionConfirmDialog.hide(),
            classes: 'btn-primary'
          }
        ];
        if (!error && success.length) {
          this.actionTitle = 'People invited';
          this.actionDetails = ['The following people were invited successfully:', success];
          if (errored.length) {
            this.actionDetails.push('The following people could not be invited:', errored);
          }
          this.actionConfirmDialog.show();
        } else {
          this.actionTitle = 'Error inviting people';
          this.actionDetails = ['There was an error inviting people. Please try again.'];
          if (errored.length) {
            this.actionDetails.push('The following people could not be invited:', errored);
          }
          this.actionConfirmDialog.show();
        }

        if (error || errors.length) {
          if (!error) {
            error = new Error('Unknown error inviting people.');
          } else {
            error.message = 'Error sending invites: ' + error.message;
          }
          if (errors.length) {
            error.message += '\n- ' + errors.join('\n- ');
          }

          throw error;
        }
        break;
      }
      default:
        this.actionConfirmDialog.hide();
    }
  }

  doCancel() {
    this.actionConfirmDialog.hide();
  }
}
