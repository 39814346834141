import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../../authentication/service/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { ReportService } from '../service/report.service';
import { ResponseCargo } from '../../domain/response/ResponseCargo';
import { PersonService } from '../../person/service/person.service';
import { ReportOptionsService } from '../service/report-options.service';
import { PanelMemberRequest } from '../service/panel-member-request';
import { AssessmentSearchCtxService } from '../../assessment/service/assessment-search-ctx.service';
import { AssessmentService } from '../../assessment/service/assessment.service';
import { TinymceUtils } from '../../tinymce/tinymce-utils';
import { AssessmentSelectorComponent } from './assessment-selector/assessment-selector.component';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { TextEditorComponent } from '../../text-editor/text-editor.component';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
})
export class ReportDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('assessmentSelectorComponent')
  assessmentSelectorComponent: AssessmentSelectorComponent;

  @ViewChild('textEditorPublicNotes')
  textEditorPublicNotes: TextEditorComponent;

  @ViewChild('textEditorPrivateNotes')
  textEditorPrivateNotes: TextEditorComponent;

  currentUser: User;

  reportEditor = false;
  editMode = false;

  reportRoleOptions = [];
  reportTypeList = [];
  peopleList = [];
  newPanelMember = new PanelMemberRequest();

  reportId: number;
  basedOnReport: number;
  selectedReport: ResponseCargo<any> = new ResponseCargo(null);

  editAvailable: boolean;
  completeAvailable: boolean;
  revertAvailable: boolean;
  publishAvailable: boolean;
  unpublishAvailable: boolean;
  archiveAvailable: boolean;
  unarchiveAvailable: boolean;

  editorSettings = TinymceUtils.editorSettings;

  saveOptions: string[] = [];

  deleteFailureMsg: string;

  removeIcon = faTrashAlt;

  constructor(
    private route: ActivatedRoute,
    private assessmentSearchCtxService: AssessmentSearchCtxService,
    private assessmentService: AssessmentService,
    private authenticationService: AuthenticationService,
    private personService: PersonService,
    private reportService: ReportService,
    private reportOptionsService: ReportOptionsService,
    private router: Router
  ) {}

  ngOnInit() {
    console.log('ReportDetailComponent.ngOnInit()');

    this.reportRoleOptions = this.reportOptionsService.getRoleOptions();
    this.reportService.getReportTypeList().subscribe((response) => {
      this.reportTypeList = response;
    });

    this.authenticationService.currentUser$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );

    const snapshot = this.route.snapshot;
    this.reportId = snapshot.params.reportId;
    this.basedOnReport = snapshot.queryParams.basedOnReport;
  }

  ngAfterViewInit(): void {
    console.log(
      'ReportDetailComponent.ngOnInit() reportId, basedOnReport = ' +
        this.reportId +
        ', ' +
        this.basedOnReport
    );
    if (this.reportId && this.reportId > -1) {
      this.loadReport();
    } else {
      this.newReport();
    }
  }

  publicNotesAttachmentsChanged() {
    // When one text editor changes attachments then update the attachments for the other text editor
    this.textEditorPrivateNotes.loadAttachments();
  }

  privateNotesAttachmentsChanged() {
    // When one text editor changes attachments then update the attachments for the other text editor
    this.textEditorPublicNotes.loadAttachments();
  }

  loadReport() {
    console.log('load report ' + this.reportId);
    this.reportService.getReport(this.reportId).subscribe(
      (response) => {
        this.selectedReport = response;
        this.determineSaveOptions();
        this.determineSecurityStatus();
        if (this.assessmentSelectorComponent) {
          this.assessmentSelectorComponent.loadAssessments();
        }
      },

      (error) => {}
    );
  }

  determineSecurityStatus() {
    const editStatus = this.selectedReport.model.editStatus;
    this.editAvailable =
      editStatus === 'DRAFT' ||
      editStatus === 'PENDING' ||
      editStatus === 'IN_PROGRESS';
    this.completeAvailable =
      editStatus === 'DRAFT' ||
      editStatus === 'PENDING' ||
      editStatus === 'IN_PROGRESS';

    this.revertAvailable = editStatus === 'COMPLETED';
    this.publishAvailable = editStatus === 'COMPLETED';
    this.unpublishAvailable = editStatus === 'PUBLISHED';

    const publishedStatus = this.selectedReport.model.publishedStatus;
    this.archiveAvailable =
      publishedStatus === 'CURRENT' && editStatus === 'PUBLISHED';
    this.unarchiveAvailable =
      publishedStatus === 'ARCHIVED' && editStatus === 'PUBLISHED';

    let currentPersonId = null;
    let currentPersonIsAdministrator = false;
    if (this.currentUser) {
      currentPersonId = this.currentUser.personId;
      currentPersonIsAdministrator = this.currentUser.administrator;
    }

    const panelMemberStatus = this.reportService.detectPanelMemberStatus(
      this.selectedReport.model,
      currentPersonId
    );
    this.reportEditor =
      panelMemberStatus.isPanelChair ||
      panelMemberStatus.isReportAdmin ||
      currentPersonIsAdministrator;

    if (this.reportEditor) {
      this.personService.findAllPersons().subscribe((response) => {
        if (response && response.searchResults) {
          for (const nextPersonFound of response.searchResults) {
            const nextPersonToAdd = {
              personId: nextPersonFound.personId,
              fullName:
                nextPersonFound.firstName + ' ' + nextPersonFound.familyName,
            };

            this.peopleList.push(nextPersonToAdd);
          }
        }
      });
    }
  }

  newReport() {
    console.log('newReport()');
    this.selectedReport = new ResponseCargo({
      editStatus: 'PENDING',
    });

    this.determineSaveOptions();
    this.reportEditor = true;
    this.editAction();
  }

  determineSaveOptions() {
    const editStatus = this.selectedReport.model.editStatus;
    this.saveOptions = [];
    if (
      editStatus === 'DRAFT' ||
      editStatus === 'PENDING' ||
      editStatus === 'IN_PROGRESS'
    ) {
      this.saveOptions.push('PENDING');
      this.saveOptions.push('IN_PROGRESS');
    }
  }

  newReportFromThisAction() {
    console.log('newReportFromThisAction() ');

    // navigating to the same page doesn't call ngInit() - but lets organise things so logically it looks like this is what it happening
    // just in case we want a link somewhere else to do it this way in the future
    this.basedOnReport = this.reportId;
    this.reportId = -1;
    this.router.navigate(['/reports/-1'], {
      queryParams: { basedOnReport: this.basedOnReport },
    });
    this.newReport();
  }

  editAction() {
    this.editMode = true;
  }

  saveAction(option: string) {
    this.selectedReport.model.notes = this.textEditorPublicNotes.text;
    this.selectedReport.model.privateNotes = this.textEditorPrivateNotes.text;
    this.selectedReport.model.editStatus = option;
    this.reportService
      .saveReport(this.selectedReport, this.basedOnReport)
      .subscribe((response) => {
        this.selectedReport = response;

        if (response.responseMessages.length === 0) {
          this.editMode = false;
          if (this.reportId < 0) {
            // need to set the reportId manually because nagivating to the same page doesn't trigger ngInit()
            this.reportId = response.model.reportId;
            // redirect the browser with the save report's reportId so that new reports get switched to the right url
            this.router
              .navigate(['/reports/' + response.model.reportId])
              .then(() => this.determineSecurityStatus());
          }
        }
      });
  }

  cancelAction() {
    if (this.reportId > 0) {
      // if not new reload the selected report to get rid of any edits
      this.editMode = false;
      this.loadReport();
      this.assessmentSelectorComponent.loadAssessments();
    } else {
      this.router.navigate(['/report-search']);
    }
  }

  updateNamesAction() {
    this.assessmentService
      .updateAssessmentNamesByReport(this.reportId)
      .subscribe(() => {
        this.loadReport();
      });
  }

  changeReportEditStatusAction(newStatus) {
    this.reportService
      .changeReportEditStatus(this.reportId, newStatus)
      .subscribe(() => {
        // chain to cancel, so that the report is refreshed correctly
        this.cancelAction();
      });
  }

  changeReportPublishedStatusAction(newStatus) {
    this.reportService
      .changeReportPublishedStatus(this.reportId, newStatus)
      .subscribe(() => {
        // chain to cancel, so that the report is refreshed correctly
        this.cancelAction();
      });
  }

  showAssessments() {
    this.assessmentSearchCtxService.clear();
    this.assessmentSearchCtxService.criteria.reportId =
      this.selectedReport.model.reportId;
    this.assessmentSearchCtxService.criteria.reportName =
      this.selectedReport.model.name;
    this.assessmentSearchCtxService.criteria.showArchived =
      this.selectedReport.model.publishedStatus === 'ARCHIVED' ? true : false;
    this.router.navigate(['assessment-search']);
  }

  addPanelMemberAction() {
    if (this.newPanelMember.personId && this.newPanelMember.reportRole) {
      this.newPanelMember.reportId = this.reportId;
      this.reportService.addPanelMember(this.newPanelMember).subscribe(() => {
        this.loadReport();
      });
    }
    // else just do nothing
  }

  removePanelMemberAction(panelMember: any) {
    const panelMemberRequest = new PanelMemberRequest();
    panelMemberRequest.reportId = this.reportId;
    panelMemberRequest.personId = panelMember.person.personId;

    this.reportService.removePanelMember(panelMemberRequest).subscribe(() => {
      this.loadReport();
    });
  }

  onDeleteAction() {
    this.reportService.removeReport(this.reportId).subscribe(
      (response) => {
        if (response.result && response.result.indexOf('OK') > 0) {
          console.log('Remove Success: ' + response.result);
          this.router.navigate(['/report-search']);
        } else {
          console.log('Remove Failed: ' + response.result);
          this.deleteFailureMsg = response.result;
        }
      },
      (error) => {
        console.log('Remove Failed: ' + error.result);
        this.deleteFailureMsg = error.result;
      }
    );
  }

  compareReportType(o1: any, o2: any) {
    return o1 && o2 && o1.code && o2.code && o1.code === o2.code;
  }
}
