// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  ga: null,
  envVar: {
    MSAL_CLIENT_ID: '22378ece-a03e-452e-ab12-464d1493799e',
    MSAL_AUTHORITY: 'https://docb2cnztcs.b2clogin.com/docb2cnztcs.onmicrosoft.com/{policy-id}',
    MSAL_KNOWN_AUTHORITIES: ["docb2cnztcs.b2clogin.com"],
    MSAL_SIGNIN_FLOW: "B2C_1_SignIn",
    MSAL_FORGET_PASSWORD_FLOW: "B2C_1_PasswordReset",
    MSAL_SIGNUP_FLOW: "B2C_1_SignUp",
  }
};
