import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PersonSearchComponent } from './person/search/person-search.component';
import { PersonEditComponent } from './person/edit/person-edit.component';
import { AdministratorAuthGuard, AuthenticatedAuthGuard, NameEditorAuthGuard } from './auth.guard';
import { HomeComponent } from './home/home.component';
import { NameMatchSearchComponent } from './nzor/name-match-search/name-match-search.component';
import { BatchControlComponent } from './batch/batch-control/batch-control.component';
import { NameMatchDetailComponent } from './nzor/name-match-detail/name-match-detail.component';
import { ReportSearchComponent } from './report/report-search/report-search.component';
import { ReportDetailComponent } from './report/report-detail/report-detail.component';
import { AssessmentSearchComponent } from './assessment/assessment-search/assessment-search.component';
import { AssessmentDetailComponent } from './assessment/assessment-detail/assessment-detail.component';
import { ContactViewComponent } from './contact/contact-view/contact-view.component';
import { AssessmentExportComponent } from './assessment/assessment-export/assessment-export.component';
import { CodeValueViewComponent } from './code/code-value-view/code-value-view.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TagListComponent } from './tag/tag-list/tag-list.component';
import { NztcsSpeciesSearchComponent } from './nztcs-species/nztcs-species-search/nztcs-species-search.component';
import { NztcsSpeciesViewComponent } from './nztcs-species/nztcs-species-view/nztcs-species-view.component';
import { TaxonNameEditComponent } from './nztcs-taxon-name/taxon-name-edit/taxon-name-edit.component';
import { TaxonSearchComponent } from './nztcs-taxon-name/taxon-search/taxon-search.component';
import { NztcsSpeciesEditComponent } from './nztcs-species/nztcs-species-edit/nztcs-species-edit.component';
import { TaxonNameViewComponent } from './nztcs-taxon-name/taxon-name-view/taxon-name-view.component';
import { ContentSearchComponent } from './content/content-search/content-search.component';
import { ContentEditComponent } from './content/content-edit/content-edit.component';
import { ContentViewComponent } from './content/content-view/content-view.component';
import { FileUploadComponent } from './attachment/file-upload/file-upload.component';
import { AttachmentSearchComponent } from './attachment/attachment-search/attachment-search.component';
import {OauthComponent} from './authentication/oauth/oauth.component';
import {LoginComponent} from './authentication/login/login.component';
import {InviteComponent} from './authentication/invite/invite.component';

/**
 * IMPORTANT: don't make changes to these routes without a corresponding change in the Java class DefaultViewController
 * otherwise clicking "refresh" on a page won't work (it won't know how to return the index.html for a deep-linked page).
 * The user will see a "Whitelabel Error Page".
 */
const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'contact', component: ContactViewComponent },
  {
    path: 'code-value',
    component: CodeValueViewComponent,
    canActivate: [AdministratorAuthGuard],
  },
  {
    path: 'batch-control',
    component: BatchControlComponent,
    canActivate: [AdministratorAuthGuard],
  },
  {
    path: 'person-search',
    component: PersonSearchComponent,
    canActivate: [AdministratorAuthGuard],
  },
  {
    path: 'person-edit/:personId',
    component: PersonEditComponent,
    canActivate: [AdministratorAuthGuard],
  },
  {
    path: 'name-match-search',
    component: NameMatchSearchComponent,
    canActivate: [NameEditorAuthGuard],
  },
  {
    path: 'name-match-detail/:speciesId',
    component: NameMatchDetailComponent,
    canActivate: [NameEditorAuthGuard],
  },
  { path: 'tags', component: TagListComponent, canActivate: [AuthenticatedAuthGuard] },
  { path: 'report-search', component: ReportSearchComponent },
  { path: 'reports/:reportId', component: ReportDetailComponent },
  { path: 'assessment-search', component: AssessmentSearchComponent },
  { path: 'assessment-export', component: AssessmentExportComponent },
  { path: 'assessments/:assessmentId', component: AssessmentDetailComponent },

  { path: 'nztcs-species-search', component: NztcsSpeciesSearchComponent },
  {
    path: 'nztcs-species/:nztcsSpeciesId',
    component: NztcsSpeciesViewComponent,
  },
  {
    path: 'nztcs-species-edit/:nztcsSpeciesId',
    component: NztcsSpeciesEditComponent,
  },

  { path: 'nztcs-taxon-search', component: TaxonSearchComponent },
  { path: 'nztcs-taxon/:nztcsTaxonId', component: TaxonNameViewComponent },
  { path: 'nztcs-taxon-edit/:nztcsTaxonId', component: TaxonNameEditComponent },

  { path: 'content-search', component: ContentSearchComponent },
  { path: 'content-edit/:contentName', component: ContentEditComponent },
  { path: 'content/:contentName', component: ContentViewComponent },

  { path: 'file-upload', component: FileUploadComponent },
  { path: 'attachment-search', component: AttachmentSearchComponent },

  { path: 'oauth2', component: OauthComponent },
  { path: 'login', component: LoginComponent },
  { path: 'invite', component: InviteComponent },

  { path: '', component: HomeComponent },
  { path: '**', component: PageNotFoundComponent },
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  appRoutes,
  { relativeLinkResolution: 'legacy' }
);
