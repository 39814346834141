import { Component, OnInit } from '@angular/core';
import {InteractionRequiredAuthError} from '@azure/msal-browser';
import {AuthenticationService} from '../service/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.handleLogin();
  }

  async handleLogin() {
    if (this.authenticationService.msalInitialising instanceof Promise) {
      await this.authenticationService.msalInitialising;
    }

    this.router.navigateByUrl("/");
  }

}
