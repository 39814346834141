import { mergeMap, tap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthenticationService } from './authentication/service/authentication.service';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public get authenticationService(): AuthenticationService {
    return this.injector.get(AuthenticationService);
  }

  intercept(
    request: HttpRequest<any>,
    nextHandler: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from((async () => {
      if (request.url.startsWith('/rest/') && request.url !== '/rest/version') {
        if (this.authenticationService.msalInitialising) {
          await this.authenticationService.msalInitialising;
        }

        const token = await this.authenticationService.getAuthToken();

        if (token) {
          request = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`)
          });
        }
      }

      return request;
    })()).pipe(mergeMap((request) => {
      return nextHandler.handle(request).pipe(
        tap(
          (event) => {},
          (err) => {
            if (err instanceof HttpErrorResponse) {
              switch (err.status) {
                case 401:
                  console.log('HttpErrorInterceptor 401');

                  const optionalMessage =
                    err.error &&
                    err.error.message &&
                    err.error.message.indexOf('JWT expired') > 0
                      ? 'Session expired, please login again.'
                      : null;
                  const router = this.injector.get(Router);

                  /*this.authenticationService.startLogin(
                    router.url,
                    optionalMessage
                  );*/
                  this.authenticationService.refreshLogin();

                  break;
                case 403:
                  console.log('HttpErrorInterceptor 403');
                  break;
                default:
                  console.log(
                    'HttpErrorInterceptor got status: ' +
                      err.status +
                      ' ' +
                      err.statusText
                  );
                  break;
              }
            } else {
              console.log(
                'HttpErrorInterceptor - some other kind of error: ' +
                  JSON.stringify(err)
              );
            }
          }
        )
      );
    }));
  }
}
