<div
  bsModal
  #messageDialogModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="messageDialogModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ header }}</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="onCancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p *ngIf="messageText">{{ messageText }}</p>

        <ng-content></ng-content>

        <div *ngIf="isArray(messageDetails)">
          <ng-template ngFor let-item [ngForOf]="messageDetails">
            <ul *ngIf="isArray(item)">
              <li *ngFor="let subItem of item">{{subItem}}</li>
            </ul>
            <p *ngIf="!isArray(item)">{{item}}</p>
          </ng-template>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6 text-right" *ngIf="actionLabel">
            <button
              id="actionBtn"
              type="button"
              (click)="onAction()"
              [class]="actionClass + ' mr-3'"
            >
              {{ actionLabel }}
            </button>
            <button
              id="cancelBtn"
              type="button"
              (click)="onCancel()"
              class="btn btn-light"
            >
              Cancel
            </button>
          </div>
          <div class="col-md-6 text-right flex gap" *ngIf="actions">
            <button
              *ngFor="let action of actions"
              type="button"
              (click)="action.handler()"
              [class]="'btn ' + (action.classes || '')"
            >{{action.label}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
