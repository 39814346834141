import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {AuthenticationService} from './authentication/service/authentication.service';

@Injectable()
export class AuthenticatedAuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  async canActivate() {
    if (this.authenticationService.msalInitialising) {
      await this.authenticationService.msalInitialising;
    }

    // TODO redirect?

    return this.authenticationService.currentUserSource.value?.authenticated || false;
  }
}

@Injectable()
export class AdministratorAuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  async canActivate() {
    if (this.authenticationService.msalInitialising) {
      await this.authenticationService.msalInitialising;
    }

    // TODO redirect?

    return this.authenticationService.currentUserSource.value?.administrator || false;
  }
}

@Injectable()
export class NameEditorAuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  async canActivate() {
    if (this.authenticationService.msalInitialising) {
      await this.authenticationService.msalInitialising;
    }

    // TODO redirect?

    return this.authenticationService.currentUserSource.value?.nameEditor || false;
  }
}
