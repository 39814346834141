import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../service/authentication.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  message: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.fragment;

    if (!token) {
      this.router.navigateByUrl('/');
      return;
    }

    this.authenticationService.checkInviteToken(token).then(() => {
        this.authenticationService.useInviteToken(token)
    }, (reason) => {
      if (reason.status === 404) {
        this.message = 'expired';
      } else {
        this.message = reason.statusText;
      }
    })
  }
}
