import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

// non-automatic imports...

import { LoginRequestEvent } from '../service/login-request-event';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @ViewChild('loginModal', { static: true })
  loginModal: ModalDirective;

  @Output()
  loginEvent = new EventEmitter();

  targetUrl: string;
  eventMessage: string;
  model: any = {};
  loading = false;
  error: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.checkLogin()
  }

  async checkLogin() {
    if (this.authenticationService.msalInitialising) {
      await this.authenticationService.msalInitialising;
    }

    if (!this.authenticationService.isLoggedIn()) {
      await this.authenticationService.login();
    }

    //if (this.router.
    const redirect = this.activatedRoute.snapshot.queryParamMap.get('r');

    if (redirect) {
      this.router.navigateByUrl(redirect);
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
