import {PersonEntity} from "../../domain/person/person-entity";

export class User {
  personId: number;
  name: string;
  token: string;
  authorities: string[];

  authenticated: boolean = false;
  nameEditor: boolean = false;
  historicalNameEditor: boolean = false;
  administrator: boolean = false;

  constructor(person?: PersonEntity) {
    if (person) {
      this.authenticated = true;
      for (const key of Object.keys(this)) {
        if (typeof person[key] !== 'undefined' && person[key] !== null) {
          this[key] = person[key];
        }
      }

      const nameParts = [];
      if (person.firstName) {
        nameParts.push(person.firstName);
      }
      if (person.familyName) {
        nameParts.push(person.familyName);
      }

      this.name = nameParts.join(' ');
    }
  }

  /** XXX @deprecated */
  updatePermissions(): void {
    this.authenticated = this.hasRole('ROLE_USER');
    this.nameEditor = this.hasRole('ROLE_NAME_EDITOR');
    this.historicalNameEditor = this.hasRole('ROLE_HISTORICAL_NAME_EDITOR');
    this.administrator = this.hasRole('ROLE_ADMINISTRATOR');
  }

  hasRole(roleName: string): boolean {
    return this.authorities && this.authorities.indexOf(roleName) > -1;
  }
}
