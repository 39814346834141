import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationRequest } from './authentication-request';
import { AuthenticationResponse } from './authentication-response';
import { User } from './user';
import { LoginRequestEvent } from './login-request-event';
import { AuthenticationResult, BrowserCacheLocation, Configuration, InteractionRequiredAuthError, PublicClientApplication, ServerError } from "@azure/msal-browser";
import {environment} from "../../../environments/environment";
import {ResponseCargo} from '../../domain/response/ResponseCargo';
import {PersonEntity} from '../../domain/person/person-entity';
import {ActivatedRoute, Router} from '@angular/router';
import {VersionService} from '../../version/version.service';


function authorityUrl(userflow: string) {
  return environment.envVar.MSAL_AUTHORITY.replaceAll('{policy-id}', userflow);
}

const tokenScope = [];

@Injectable()
export class AuthenticationService {
  public currentUserSource = new BehaviorSubject<User>(null);
  public currentUser$ = this.currentUserSource.asObservable();

  public loginRequestSource = new BehaviorSubject<any>(null);
  public loginRequest$ = this.loginRequestSource.asObservable();

  public msalInstance: PublicClientApplication;
  public msalInitialising: Promise<void>|null = null;

  private stateSeparator = ',';

  private msalConfig: Configuration;

  constructor(
    private http: HttpClient,
    private router: Router,
    private versionService: VersionService
  ) {
    this.handleAuthErrorResponse = this.handleAuthErrorResponse.bind(this);
    this.msalInitialising = this.versionService.getVersion().toPromise().then((version) => {
      this.msalConfig = {
        auth: {
            clientId: version.id,
            authority: authorityUrl(environment.envVar.MSAL_SIGNIN_FLOW),
            knownAuthorities: environment.envVar.MSAL_KNOWN_AUTHORITIES,
            redirectUri: '/oauth2',
            //postLogoutRedirectUri: '/'
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage
        }
      };
      this.msalInstance = new PublicClientApplication(this.msalConfig);

      return this.msalInstance.initialize().then(() => {
        return this.msalInstance.handleRedirectPromise().then(
          async (tokenResponse) => {
            // Needs to be nulled before running handleLoginResponse
            // as it calls an API endpoint
            this.msalInitialising = null;
            await this.handleLoginResponse(tokenResponse);
          },
          this.handleAuthErrorResponse
        );
      });
    });

    // set token if saved in local storage
    const nextUser = JSON.parse(localStorage.getItem('currentUser'));

    // Note: although we're loading the authorities from an untrusted source
    // hacking these to give yourself elevated privileges still won't achieve
    // very much because it's the token that sent through to the server to get
    // the data and that can't be tampered with because it's digitally signed.
    this.setCurrentUser(nextUser);

    this.initialiseCurrentUser();
  }

  handleAuthErrorResponse(error: ServerError) {
    if (error.errorMessage.startsWith('AADB2C90118:')) { // The user has forgetten their password
      this.msalInstance.loginRedirect({
        scopes: tokenScope,
        authority: authorityUrl(environment.envVar.MSAL_FORGET_PASSWORD_FLOW),
      });
    } else if (error.errorMessage.startsWith('AADB2C90091:') || error.errorCode == 'user_cancelled') { // The user has cancelled entering self-asserted information
      this.logout();
    }

    this.msalInitialising = null;
  }

  async handleLoginResponse(tokenResponse: AuthenticationResult) {
    if (tokenResponse?.account) {
      this.msalInstance.setActiveAccount(tokenResponse.account);
    }

    const account = this.msalInstance.getActiveAccount();

    console.log('got active account', account);

    if (account) {
      if (tokenResponse == null) {
        try {
          await this.msalInstance.acquireTokenSilent({
            scopes: tokenScope
          });
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            this.login();
          }
        }
      }

      const user = new User();
      user.token = account.idToken;
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSource.next(user);

      // Check if given state (will be from an
      if (account.tenantId == environment.envVar.MSAL_SIGNUP_FLOW && tokenResponse?.state) {
        const [type, value] = tokenResponse.state.split(this.stateSeparator, 2);

        switch (type) {
          case 'i': {// Invitation token
            // Link auth account to nztcs account
            const response = await this.http.post(
              '/rest/person/link',
               value,
              {
                observe: "response",
                responseType: "text"
              }
            ).toPromise();

            if (response.status === 200) {
              this.router.navigateByUrl('/');
            }
            break;
          }
        }
      }

      // Get user details from API
      await this.http.get<ResponseCargo<PersonEntity>>('/rest/person/currentUser').toPromise().then((response) => {
        if (response.model) {
          const user = new User(response.model);
          user.token = account.idToken;
          this.currentUserSource.next(user);
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.msalInitialising = null
        }
      });
    }
  }

  async initialiseCurrentUser() {
    if (this.msalInitialising instanceof Promise) {
      await this.msalInitialising;
    }

    this.msalInstance.getActiveAccount();
  }

  setCurrentUser(currentUser: any) {
    if (!currentUser) {
      currentUser = new User();
    }

    this.currentUserSource.next(currentUser);
  }

  async logout() {
    // clear token remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.setCurrentUser(null);
    if (this.msalInitialising) {
      await this.msalInitialising;
    }
    await this.msalInstance.logoutRedirect();
    //await this.msalInstance.clearCache();
  }

  isLoggedIn() {
    const currentUserNow = this.currentUserSource.getValue();
    return currentUserNow && currentUserNow.token;
  }

  async getAuthToken() {
    if (this.isLoggedIn()) {
      if (this.msalInitialising) {
        await this.msalInitialising;
      }
      if (this.msalInstance.getActiveAccount()) {
        try {
          const response = await this.msalInstance.acquireTokenSilent({
            scopes: tokenScope,
            // The tenantId of the active account is the user flow that the
            // tokens are from, normall signin, but will be signup after
            // invite, so use the tenantId to make sure requesting the
            // refreshed token from the right place
            authority: authorityUrl(this.msalInstance.getActiveAccount().tenantId)
          });

          return response.idToken;
        } catch (ex) {
          // TODO Get error error=interaction_required&error_description=AADB2C90077%3a+User+does+not+have+an+existing+session+and+request+prompt+parameter+has+a+value+of+
          await this.logout();
        }
      }
    }
    return null;
  }

  async checkInviteToken(token: string) {
    return this.http.post('/rest/person/checkInvite', token, {
      observe: 'response',
      responseType: 'text'
    }).toPromise();
  }

  async useInviteToken(token) {
    if (this.msalInitialising instanceof Promise) {
      await this.msalInitialising;
    }

    this.msalInstance.loginRedirect({
      scopes: [],
      authority: authorityUrl(environment.envVar.MSAL_SIGNUP_FLOW),
      state: 'i' + this.stateSeparator + token
    });
  }

  async login() {
    if (this.msalInitialising instanceof Promise) {
      await this.msalInitialising;
    }

    this.msalInstance.loginRedirect({
      scopes: []
    });
  }

  refreshingLogin: Promise<void> = null;

  refreshLogin() {
    if (this.refreshingLogin) {
      return this.refreshingLogin;
    }

    this.refreshingLogin = this._refreshLogin();
    return this.refreshingLogin;
  }

  async _refreshLogin() {
    if (this.msalInitialising instanceof Promise) {
      await this.msalInitialising;
    }

    if (!this.msalInstance.getActiveAccount()) {
      // TODO Does this need a logout?
      return;
    }

    try {
      const loginResponse = await this.msalInstance.loginPopup({
        scopes: []
      });

      await this.handleLoginResponse(loginResponse);

      this.refreshingLogin = null;
    } catch (err) {
      this.handleAuthErrorResponse(err);
    }
  }
}
