<div class="container-fluid">
  <h2>Person search</h2>
  <hr />

  <form class="form-inline">
    <div class="form-group">
      <label class="mr-3" for="nameCriteriaTxtFld">Name</label>
      <input
        id="nameCriteriaTxtFld"
        type="text"
        class="form-control mr-3"
        [(ngModel)]="personCriteria.name"
        name="nameCriteriaTxtFld"
      />
    </div>

    <button type="submit" (click)="searchAction()" class="btn btn-primary mr-3">
      Search
    </button>
    <button type="button" (click)="clearAction()" class="btn btn-light mr-3">
      Clear
    </button>
    <button
      type="button"
      (click)="newPersonAction()"
      class="btn btn-success mr-3"
    >
      New Person
    </button>
  </form>

  <br />

  <div class="flex gap">
    <h3 class="grow">Search Results</h3>
    <div class="flex gap baseline"
      *ngIf="response && (allSelected ? response.total != selectedIds.size : selectedIds.size != 0)"
    >
      <label>
        with selected:
        <select [(ngModel)]="bulkAction">
          <option>Invite</option>
        </select>
      </label>
      <button type="button"
        [disabled]="!bulkAction"
        (click)="confirmAction()"
      >
        Go
      </button>
    </div>
  </div>

  <table class="table table-bordered table-sm">
    <thead>
      <tr>
        <th class="actions">
          <input type="checkbox" #selectAll
            [(ngModel)]="allSelected"
          >
        </th>
        <th>Name</th>
        <th>Username / Email</th>
        <th class="actions"></th>
      </tr>
    </thead>
    <tbody *ngIf="response?.searchResults.length > 0">
      <tr *ngFor="let person of response.searchResults; let i = index">
        <td>
          <input type="checkbox"
            [checked]="allSelected != selectedIds.has(person.personId)"
            (change)="toggleSelection($event, i)"
          >
        <td>
          <a routerLink="/person-edit/{{ person.personId }}"
            >{{ person.firstName }} {{ person.familyName }}</a
          >
          <fa-icon [icon]="faUserXmark" size="xs"
            *ngIf="!person.authId"
            style="margin-left: 10px"
            title="Person has no registered user account"
          ></fa-icon>
        </td>
        <td>{{ person.userName }}</td>
        <td>
          <button type="button" class="btn icon"
            *ngIf="!person.authId"
            title="Invite person to register"
            (click)="invitePerson(person)"
          >
            <fa-icon [icon]="faEnvelopeOpenText"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="!response?.searchResults.length">
      <td colspan="100">No matching search results found.</td>
    </tbody>
  </table>

  <div *ngIf="response" class="flex gap wrap baseline">
    <pagination
      class="grow"
      [maxSize]="5"
      [itemsPerPage]="personCriteria.pageSize"
      [(ngModel)]="personCriteria.pageNumber"
      [totalItems]="response?.total"
      (pageChanged)="onPageChanged($event)"
    >
    </pagination>
    <label>
      People per page:
      <select (change)="onPageSizeChanged($event)"
      [value]="personCriteria.pageSize"
      >
        <option>15</option>
        <option>30</option>
        <option>100</option>
      </select>
    </label>
  </div>
</div>

<app-message-dialog
  #actionConfirmDialog
  [header]="actionTitle"
  [actions]="actions"
  [messageText]="actionPrompt"
  [messageDetails]="actionDetails"
></app-message-dialog>