import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseCargo } from '../../domain/response/ResponseCargo';
import { PanelMemberRequest } from './panel-member-request';
import { PanelMemberStatus } from './panel-member-status';
import { ReportSearchCriteria } from './report-search-criteria';
import { AddAssessmentRequest } from './add-assessment-request';
import { RemoveAssessmentRequest } from './remove-assessment-request';

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) {}

  //@RequestMapping(value="/availableYears", method = RequestMethod.GET)
  //public List<Integer> availableYears() {
  // getAvailableYears(): Observable<number[]> {
  //   return this.http.get<number[]>('/rest/reports/availableYears');
  // }

  //@RequestMapping(value = "/reportNames", method = RequestMethod.GET)
  //public List<String> getReportNames(@RequestParam("showArchived") boolean showArchived) {
  getReportNames(showArchived): Observable<string[]> {
    return this.http.get<string[]>(
      '/rest/reports/reportNames?showArchived=' + showArchived
    );
  }

  //@RequestMapping(value="/{reportId}", method = RequestMethod.GET)
  //public Response<Report> getReport(@PathVariable("reportId")Integer reportId) {
  getReport(reportId: number) {
    return this.http.get<ResponseCargo<any>>('/rest/reports/' + reportId);
  }

  //@RequestMapping(value="/findMatchResultSummary/{reportId}", method = RequestMethod.GET)
  //public MatchResultSummary findMatchResultSummary(@PathVariable("reportId")Integer reportId) {
  // findMatchResultSummary(reportId: number): Observable<ResponseCargo> {
  //   return this.http.get<ResponseCargo>('/rest/reports/findMatchResultSummary/' + reportId);
  // }

  //@RequestMapping(value = "/addPanelMember", method = RequestMethod.POST)
  //public void addPanelMember(@RequestBody PanelMemberRequest panelMemberRequest) {
  addPanelMember(panelMemberRequest: PanelMemberRequest) {
    return this.http.post('/rest/reports/addPanelMember', panelMemberRequest);
  }

  //@RequestMapping(value = "/removePanelMember", method = RequestMethod.POST)
  //public void removePanelMember(@RequestBody PanelMemberRequest panelMemberRequest) {
  removePanelMember(panelMemberRequest: PanelMemberRequest) {
    return this.http.post(
      '/rest/reports/removePanelMember',
      panelMemberRequest
    );
  }

  //@RequestMapping(value="/search", method = RequestMethod.POST)
  //@ResponseBody
  //public ReportSearchResponse search(@RequestBody ReportSearchCriteria searchCriteria) {
  search(criteria: ReportSearchCriteria) {
    return this.http.post('/rest/reports/search', criteria);
  }

  //@RequestMapping(value="/reportTypeList", method = RequestMethod.GET)
  //public List<ReportType> reportTypeList() {
  getReportTypeList(): Observable<any[]> {
    return this.http.get<any[]>('/rest/reports/reportTypeList');
  }

  //@RequestMapping(method = RequestMethod.POST)
  //public  nz.govt.doc.nztcs.domain.ws.nztcsservice.Response saveReport(@RequestBody Response<Report> request, @PathParam("basedOnReport")Integer basedOnReport) {
  saveReport(report: any, basedOnReport: number): Observable<any> {
    const options: any = {};
    if (basedOnReport) {
      options.params = new HttpParams().set(
        'basedOnReport',
        basedOnReport.toString()
      );
    }

    return this.http.post('/rest/reports', report, options);
  }

  removeReport(reportId: number): Observable<any> {
    return this.http.delete('/rest/reports/' + reportId);
  }

  //@RequestMapping(value = "/notes", method = RequestMethod.POST)
  //public  void saveReportNotes(@PathVariable("reportId")Integer reportId, @RequestBody String notes) {

  //@RequestMapping(value="/changeReportEditStatus/{reportId}/{newStatus}", method = RequestMethod.GET)
  //public void changeReportEditStatus(@PathVariable("reportId")Integer reportId, @PathVariable("newStatus")EditStatus newStatus) {
  changeReportEditStatus(reportId: number, newStatus: string): Observable<any> {
    return this.http.get(
      '/rest/reports/changeReportEditStatus/' + reportId + '/' + newStatus
    );
  }

  //@RequestMapping(value="/changeReportPublishedStatus/{reportId}/{newStatus}", method = RequestMethod.GET)
  //public void changeReportPublishedStatus(@PathVariable("reportId")Integer reportId, @PathVariable("newStatus")PublishedStatus publishedStatus) {
  changeReportPublishedStatus(
    reportId: number,
    newStatus: string
  ): Observable<any> {
    return this.http.get(
      '/rest/reports/changeReportPublishedStatus/' + reportId + '/' + newStatus
    );
  }

  //@RequestMapping(value="/findSpeciesReportItems", method = RequestMethod.POST)
  //@ResponseBody
  //public PagedSearchResponse findSpeciesReportItems(@RequestBody SpeciesReportSearchCriteria searchCriteria) {

  //@RequestMapping(value = "/addMatchRule", method = RequestMethod.POST)
  //public void addMatchRule(@RequestBody MatchRuleRequest matchRuleRequest) {

  //@RequestMapping(value = "/removeMatchRule", method = RequestMethod.POST)
  //public void removeMatchRule(@RequestBody MatchRuleRequest matchRuleRequest) {

  //@RequestMapping(value = "/updateAssessments", method = RequestMethod.POST)
  //public void updateAssessments(@RequestBody UpdateAssessmentsRequest request) {

  addAssessments(reportId: number, speciesList: number[]) {
    const addAssessmentRequest = new AddAssessmentRequest(
      reportId,
      speciesList
    );
    return this.http.post('/rest/reports/addAssessments', addAssessmentRequest);
  }

  removeAssessments(reportId: number, assessmentList: number[]) {
    const removeAssessmentRequest = new RemoveAssessmentRequest(
      reportId,
      assessmentList
    );
    return this.http.post(
      '/rest/reports/removeAssessments',
      removeAssessmentRequest
    );
  }

  detectPanelMemberStatus(report, currentPersonId): PanelMemberStatus {
    const panelMemberStatus = new PanelMemberStatus();

    for (const member of report.panelMembers) {
      if (member.person.personId === currentPersonId) {
        panelMemberStatus.isPanelMember = member.reportRole === 'MEMBER';
        panelMemberStatus.isPanelChair = member.reportRole === 'CHAIR';
        panelMemberStatus.isReportAdmin = member.reportRole === 'ADMINISTRATOR';
      }
    }

    return panelMemberStatus;
  }
}
