export class PersonEntity {
  public administrator: boolean = false;
  public familyName: string = null;
  public firstName: string = null;
  public historicalNameEditor: boolean = false;
  public nameEditor: boolean = false;
  public passwordEdit: string = null;
  public personId: number = null;
  public personType: 'EXTERNAL' = 'EXTERNAL';
  public userName: string = null;
  public authId: string = null;

  static newPerson() {
    return new PersonEntity();
  }
}
